// 政策文件
const policyDocument = (echarts, xData, seriesVal) => {
  const myChart = echarts.init(document.querySelector('.bar .chart'));
  // 2. 指定配置项和数据
  const option = {
    color: [ '#2f89cf' ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    // 修改图表的大小
    grid: {
      left: '0%',
      top: '10px',
      right: '0%',
      bottom: '4%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: xData,
        // data: ['5月', '6月', '7月', '8月', '9月', '10月'],
        axisTick: {
          alignWithLabel: true
        },
        // 修改刻度标签 相关样式
        axisLabel: {
          color: 'rgba(255,255,255,.6) ',
          fontSize: '12'
        },
        // 不显示x坐标轴的样式
        axisLine: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        // 修改刻度标签 相关样式
        axisLabel: {
          color: 'rgba(255,255,255,.6) ',
          fontSize: 6
        },
        // y轴的线条改为了 2像素
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.1)',
            width: 2
          }
        },
        // y轴分割线的颜色
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.1)'
          }
        }
      }
    ],
    series: [
      {
        name: '',
        type: 'bar',
        barWidth: '35%',
        data: seriesVal,
        // data: [33292, 34081, 34833, 36469, 36941, 36941],
        label: {
          show: true,
          position: 'top',
          color: '#fff'
        },
        itemStyle: {
          // 修改柱子圆角
          barBorderRadius: 5
        }
      }
    ]
  };
  // 3. 把配置项给实例对象
  myChart.setOption(option);
  // 4. 让图表跟随屏幕自动的去适应
  window.addEventListener('resize', function () {
    myChart.resize();
  });
};
// 政策浏览数（按行业）
const numOfPolicy = (echarts, legendData, xData, seriesVal) => {
  const myColor = [ '#f00', 'rgba(221, 220, 107, .1)', '#56D0E3', '#F8B448', '#00d887' ];
  // 1. 实例化对象
  const myChart = echarts.init(document.querySelector('.bar2 .chart'));
  const series = [];
  seriesVal.forEach((ele, index) => {
    series.push({
      name: ele.name,
      type: 'line',
      smooth: true,
      // 单独修改当前线条的样式
      lineStyle: {
        color: myColor[index],
        width: '2'
      },
      // 填充颜色设置
      areaStyle: {
        color: new echarts.graphic.LinearGradient(
          0,
          0,
          0,
          1,
          [
            {
              offset: 0,
              color: 'rgba(1, 132, 213, 0.4)' // 渐变色的起始颜色
            },
            {
              offset: 0.8,
              color: 'rgba(1, 132, 213, 0.1)' // 渐变线的结束颜色
            }
          ],
          false
        ),
        shadowColor: 'rgba(0, 0, 0, 0.1)'
      },
      // 设置拐点
      symbol: 'circle',
      // 拐点大小
      symbolSize: 8,
      // 开始不显示拐点， 鼠标经过显示
      showSymbol: false,
      // 设置拐点颜色以及边框
      itemStyle: {
        color: myColor[index],
        borderColor: 'rgba(221, 220, 107, .1)',
        borderWidth: 12
      },
      data: ele.count
    });
  });
  // 2. 指定配置和数据
  const option = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      top: '0%',
      data: legendData,
      // data: ['文体类', '商业服务', '制造业', '金融业', '信息技术'],
      textStyle: {
        color: 'rgba(255,255,255,.5)',
        fontSize: '10'
      }
    },

    grid: {
      left: '10',
      top: '30',
      right: '10',
      bottom: '10',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        // x轴更换数据
        data: xData,
        // data: [
        //   '10-17',
        //   '10-18',
        //   '10-19',
        //   '10-20',
        //   '10-21',
        //   '10-22',
        //   '10-23',
        //   '10-24',
        //   '10-25',
        //   '10-26'
        // ],
        // 文本颜色为rgba(255,255,255,.6)  文字大小为 12
        axisLabel: {
          interval: 1,
          textStyle: {
            color: 'rgba(255,255,255,.6)',
            fontSize: 12
          }
        },
        // x轴线的颜色为   rgba(255,255,255,.2)
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.2)'
          }
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        axisTick: { show: false },
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.1)'
          }
        },
        axisLabel: {
          textStyle: {
            color: 'rgba(255,255,255,.6)',
            fontSize: 6
          }
        },
        // 修改分割线的颜色
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.1)'
          }
        }
      }
    ],
    series: series
    // [
    //   {
    //     name: '文体类',
    //     type: 'line',
    //     smooth: true,
    //     // 单独修改当前线条的样式
    //     lineStyle: {
    //       color: '#f00',
    //       width: '2'
    //     },
    //     // 填充颜色设置
    //     areaStyle: {
    //       color: new echarts.graphic.LinearGradient(
    //         0,
    //         0,
    //         0,
    //         1,
    //         [
    //           {
    //             offset: 0,
    //             color: 'rgba(1, 132, 213, 0.4)' // 渐变色的起始颜色
    //           },
    //           {
    //             offset: 0.8,
    //             color: 'rgba(1, 132, 213, 0.1)' // 渐变线的结束颜色
    //           }
    //         ],
    //         false
    //       ),
    //       shadowColor: 'rgba(0, 0, 0, 0.1)'
    //     },
    //     // 设置拐点
    //     symbol: 'circle',
    //     // 拐点大小
    //     symbolSize: 8,
    //     // 开始不显示拐点， 鼠标经过显示
    //     showSymbol: false,
    //     // 设置拐点颜色以及边框
    //     itemStyle: {
    //       color: '#f00',
    //       borderColor: 'rgba(221, 220, 107, .1)',
    //       borderWidth: 12
    //     },
    //     data: [441, 258, 406, 439, 392, 333, 338, 429, 242, 268]
    //   },
    //   {
    //     name: '商业服务',
    //     type: 'line',
    //     smooth: true,
    //     // 单独修改当前线条的样式
    //     lineStyle: {
    //       color: 'rgba(221, 220, 107, .1)',
    //       width: '2'
    //     },
    //     // 填充颜色设置
    //     areaStyle: {
    //       color: new echarts.graphic.LinearGradient(
    //         0,
    //         0,
    //         0,
    //         1,
    //         [
    //           {
    //             offset: 0,
    //             color: 'rgba(1, 132, 213, 0.4)' // 渐变色的起始颜色
    //           },
    //           {
    //             offset: 0.8,
    //             color: 'rgba(1, 132, 213, 0.1)' // 渐变线的结束颜色
    //           }
    //         ],
    //         false
    //       ),
    //       shadowColor: 'rgba(0, 0, 0, 0.1)'
    //     },
    //     // 设置拐点
    //     symbol: 'circle',
    //     // 拐点大小
    //     symbolSize: 8,
    //     // 开始不显示拐点， 鼠标经过显示
    //     showSymbol: false,
    //     // 设置拐点颜色以及边框
    //     itemStyle: {
    //       color: 'rgba(221, 220, 107, .1)',
    //       borderColor: 'rgba(221, 220, 107, .1)',
    //       borderWidth: 12
    //     },
    //     data: [270, 234, 399, 273, 263, 350, 291, 233, 309, 364]
    //   },
    //   {
    //     name: '制造业',
    //     type: 'line',
    //     smooth: true,
    //     // 单独修改当前线条的样式
    //     lineStyle: {
    //       color: '#56D0E3',
    //       width: '2'
    //     },
    //     // 填充颜色设置
    //     areaStyle: {
    //       color: new echarts.graphic.LinearGradient(
    //         0,
    //         0,
    //         0,
    //         1,
    //         [
    //           {
    //             offset: 0,
    //             color: 'rgba(1, 132, 213, 0.4)' // 渐变色的起始颜色
    //           },
    //           {
    //             offset: 0.8,
    //             color: 'rgba(1, 132, 213, 0.1)' // 渐变线的结束颜色
    //           }
    //         ],
    //         false
    //       ),
    //       shadowColor: 'rgba(0, 0, 0, 0.1)'
    //     },
    //     // 设置拐点
    //     symbol: 'circle',
    //     // 拐点大小
    //     symbolSize: 8,
    //     // 开始不显示拐点， 鼠标经过显示
    //     showSymbol: false,
    //     // 设置拐点颜色以及边框
    //     itemStyle: {
    //       color: '#56D0E3',
    //       borderColor: 'rgba(221, 220, 107, .1)',
    //       borderWidth: 12
    //     },
    //     data: [286, 350, 299, 220, 391, 403, 319, 245, 361, 385]
    //   },
    //   {
    //     name: '金融业',
    //     type: 'line',
    //     smooth: true,
    //     // 单独修改当前线条的样式
    //     lineStyle: {
    //       color: '#F8B448',
    //       width: '2'
    //     },
    //     // 填充颜色设置
    //     areaStyle: {
    //       color: new echarts.graphic.LinearGradient(
    //         0,
    //         0,
    //         0,
    //         1,
    //         [
    //           {
    //             offset: 0,
    //             color: 'rgba(1, 132, 213, 0.4)' // 渐变色的起始颜色
    //           },
    //           {
    //             offset: 0.8,
    //             color: 'rgba(1, 132, 213, 0.1)' // 渐变线的结束颜色
    //           }
    //         ],
    //         false
    //       ),
    //       shadowColor: 'rgba(0, 0, 0, 0.1)'
    //     },
    //     // 设置拐点
    //     symbol: 'circle',
    //     // 拐点大小
    //     symbolSize: 8,
    //     // 开始不显示拐点， 鼠标经过显示
    //     showSymbol: false,
    //     // 设置拐点颜色以及边框
    //     itemStyle: {
    //       color: '#F8B448',
    //       borderColor: 'rgba(221, 220, 107, .1)',
    //       borderWidth: 12
    //     },
    //     data: [308, 434, 309, 385, 329, 380, 361, 325, 310, 520]
    //   },
    //   {
    //     name: '信息技术',
    //     type: 'line',
    //     smooth: true,
    //     lineStyle: {
    //       normal: {
    //         color: '#00d887',
    //         width: 2
    //       }
    //     },
    //     areaStyle: {
    //       normal: {
    //         color: new echarts.graphic.LinearGradient(
    //           0,
    //           0,
    //           0,
    //           1,
    //           [
    //             {
    //               offset: 0,
    //               color: 'rgba(0, 216, 135, 0.4)'
    //             },
    //             {
    //               offset: 0.8,
    //               color: 'rgba(0, 216, 135, 0.1)'
    //             }
    //           ],
    //           false
    //         ),
    //         shadowColor: 'rgba(0, 0, 0, 0.1)'
    //       }
    //     },
    //     // 设置拐点 小圆点
    //     symbol: 'circle',
    //     // 拐点大小
    //     symbolSize: 5,
    //     // 设置拐点颜色以及边框
    //     itemStyle: {
    //       color: '#00d887',
    //       borderColor: 'rgba(221, 220, 107, .1)',
    //       borderWidth: 12
    //     },
    //     // 开始不显示拐点， 鼠标经过显示
    //     showSymbol: false,
    //     data: [283, 424, 356, 418, 283, 305, 422, 323, 292, 416]
    //   }
    // ]
  };

  // 3. 把配置给实例对象
  myChart.setOption(option);
  // 4. 让图表跟随屏幕自动的去适应
  window.addEventListener('resize', function () {
    myChart.resize();
  });
};
// 扶持项目
const supportingPro = (echarts, xData, seriesVal) => {
  // 1. 实例化对象
  const myChart = echarts.init(document.querySelector('.line .chart'));
  // 2.指定配置
  const option = {
    color: [ '#00d887' ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    // 修改图表的大小
    grid: {
      left: '0%',
      top: '10px',
      right: '0%',
      bottom: '4%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: xData,
        // data: ['5月', '6月', '7月', '8月', '9月', '10月'],
        axisTick: {
          alignWithLabel: true
        },
        // 修改刻度标签 相关样式
        axisLabel: {
          color: 'rgba(255,255,255,.6) ',
          fontSize: '12'
        },
        // 不显示x坐标轴的样式
        axisLine: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        // 修改刻度标签 相关样式
        axisLabel: {
          color: 'rgba(255,255,255,.6) ',
          fontSize: 6
        },
        // y轴的线条改为了 2像素
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.1)',
            width: 2
          }
        },
        // y轴分割线的颜色
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.1)'
          }
        }
      }
    ],
    series: [
      {
        name: '',
        type: 'bar',
        barWidth: '40%',
        data: seriesVal,
        // data: [3529, 3640, 3764, 3972, 4038, 4038],
        label: {
          show: true,
          position: 'top',
          color: '#fff'
        },
        itemStyle: {
          // 修改柱子圆角
          barBorderRadius: 5
        }
      }
    ]
  };
  // 3. 把配置项给实例对象
  myChart.setOption(option);
  // 4. 让图表跟随屏幕自动的去适应
  window.addEventListener('resize', function () {
    myChart.resize();
  });
};
// 项目累计数（按区域）
const numOfProject = (echarts, xData, seriesVal) => {
  const myChart = echarts.init(document.querySelector('.line2 .chart'));
  const option = {
    color: [ '#2f89cf' ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    // 修改图表的大小
    grid: {
      left: '0%',
      top: '10px',
      right: '0%',
      bottom: '4%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: xData,
        // data: ['禅城区', '南海区', '顺德区', '三水区', '高明区'],
        axisTick: {
          alignWithLabel: true
        },
        // 修改刻度标签 相关样式
        axisLabel: {
          color: 'rgba(255,255,255,.6) ',
          fontSize: '12'
        },
        // 不显示x坐标轴的样式
        axisLine: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        // 修改刻度标签 相关样式
        axisLabel: {
          color: 'rgba(255,255,255,.6) ',
          fontSize: 6
        },
        // y轴的线条改为了 2像素
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.1)',
            width: 2
          }
        },
        // y轴分割线的颜色
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.1)'
          }
        }
      }
    ],
    series: [
      {
        name: '',
        type: 'bar',
        barWidth: '42%',
        data: seriesVal,
        // data: [393, 674, 659, 350, 267],
        label: {
          show: true,
          position: 'top',
          color: '#fff'
        },
        itemStyle: {
          // 修改柱子圆角
          barBorderRadius: 5
        }
      }
    ]
  };
  myChart.setOption(option);
  // 4. 让图表跟随屏幕自动的去适应
  window.addEventListener('resize', function () {
    myChart.resize();
  });
};
// 项目额度分布(万元)
const projectQuota = (echarts, seriesVal) => {
  // 1. 实例化对象
  const myChart = echarts.init(
    document.querySelector('.pie .panel-pie .pie-one .chart')
  );
  // 2.指定配置
  const option = {
    color: [ '#f00', '#fff', '#56D0E3', '#F8B448', '#00d887' ],
    // color: [ '#065aab', '#066eab', '#0682ab', '#0696ab', '#06a0ab' ],
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    title: {
      text: '项目额度分布(万元)',
      left: 'center',
      top: 1,
      textStyle: {
        color: '#ccc',
        fontSize: 12
      }
    },
    legend: {
      bottom: '0%',
      // 修改小图标的大小
      itemWidth: 10,
      itemHeight: 10,
      // 修改图例组件的文字为 12px
      textStyle: {
        color: 'rgba(255,255,255,.5)',
        fontSize: '12'
      }
    },
    series: [
      {
        name: '',
        type: 'pie',
        // 这个radius可以修改饼形图的大小
        // radius 第一个值是内圆的半径 第二个值是外圆的半径
        radius: [ '40%', '60%' ],
        center: [ '50%', '45%' ],
        avoidLabelOverlap: false,
        // 图形上的文字
        label: {
          show: false,
          position: 'center'
        },
        // 链接文字和图形的线是否显示
        labelLine: {
          show: false
        },
        data: seriesVal
        // data: [
        //   { value: 1, name: '0-10' },
        //   { value: 4, name: '10-50' },
        //   { value: 2, name: '50-100' },
        //   { value: 2, name: '100以上' }
        // ]
      }
    ]
  };

  // 3. 把配置给实例对象
  myChart.setOption(option);
  // 4. 让图表跟随屏幕自动的去适应
  window.addEventListener('resize', function () {
    myChart.resize();
  });
};
// 政策级别分布
const policyLevel = (echarts, seriesVal) => {
  // 1. 实例化对象
  const myChart = echarts.init(
    document.querySelector('.pie .panel-pie .pie-two .chart')
  );
  // 2.指定配置
  const option = {
    color: [ '#f00', '#fff', '#56D0E3', '#F8B448', '#00d887' ],
    // color: [ '#065aab', '#066eab', '#0682ab', '#0696ab', '#06a0ab' ],
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    title: {
      text: '政策级别分布',
      left: 'center',
      top: 1,
      textStyle: {
        color: '#ccc',
        fontSize: 12
      }
    },
    legend: {
      bottom: '0%',
      // 修改小图标的大小
      itemWidth: 10,
      itemHeight: 10,
      // 修改图例组件的文字为 12px
      textStyle: {
        color: 'rgba(255,255,255,.5)',
        fontSize: '12'
      }
    },
    series: [
      {
        name: '',
        type: 'pie',
        // 这个radius可以修改饼形图的大小
        // radius 第一个值是内圆的半径 第二个值是外圆的半径
        radius: [ '40%', '60%' ],
        center: [ '50%', '45%' ],
        avoidLabelOverlap: false,
        // 图形上的文字
        label: {
          show: false,
          position: 'center'
        },
        // 链接文字和图形的线是否显示
        labelLine: {
          show: false
        },
        data: seriesVal
        // data: [
        //   { value: 1, name: '中央' },
        //   { value: 4, name: '省级' },
        //   { value: 2, name: '市级' },
        //   { value: 2, name: '区级' },
        //   { value: 2, name: '镇街级' }
        // ]
      }
    ]
  };

  // 3. 把配置给实例对象
  myChart.setOption(option);
  // 4. 让图表跟随屏幕自动的去适应
  window.addEventListener('resize', function () {
    myChart.resize();
  });
};
// 注册用户数
const numOfUsers = (echarts, legendData, xData, seriesVal) => {
  const myChart = echarts.init(document.querySelector('.pie2 .chart'));
  const option = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      top: '0%',
      data: legendData,
      // data: ['注册数'],
      textStyle: {
        color: '#f00',
        fontSize: '10'
      }
    },

    grid: {
      left: '10',
      top: '30',
      right: '10',
      bottom: '10',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        // x轴更换数据
        data: xData,
        // data: ['5月', '6月', '7月', '8月', '9月', '10月'],
        // 文本颜色为rgba(255,255,255,.6)  文字大小为 12
        axisLabel: {
          textStyle: {
            color: 'rgba(255,255,255,.6)',
            fontSize: 12
          }
        },
        // x轴线的颜色为   rgba(255,255,255,.2)
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.2)'
          }
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        axisTick: { show: false },
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.1)'
          }
        },
        axisLabel: {
          textStyle: {
            color: 'rgba(255,255,255,.6)',
            fontSize: 6
          }
        },
        // 修改分割线的颜色
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.1)'
          }
        }
      }
    ],
    series: [
      {
        name: '注册数',
        type: 'line',
        smooth: true,
        // 单独修改当前线条的样式
        lineStyle: {
          color: '#f00',
          width: '2'
        },
        // 填充颜色设置
        areaStyle: {
          color: new echarts.graphic.LinearGradient(
            0,
            0,
            0,
            1,
            [
              {
                offset: 0,
                color: 'rgba(1, 132, 213, 0.4)' // 渐变色的起始颜色
              },
              {
                offset: 0.8,
                color: 'rgba(1, 132, 213, 0.1)' // 渐变线的结束颜色
              }
            ],
            false
          ),
          shadowColor: 'rgba(0, 0, 0, 0.1)'
        },
        // 设置拐点
        symbol: 'circle',
        // 拐点大小
        symbolSize: 8,
        // 开始不显示拐点， 鼠标经过显示
        showSymbol: false,
        // 设置拐点颜色以及边框
        itemStyle: {
          color: '#f00',
          borderColor: 'rgba(221, 220, 107, .1)',
          borderWidth: 12
        },
        data: seriesVal
      }
    ]
  };
  myChart.setOption(option);
  // 监听浏览器缩放，图表对象调用缩放resize函数
  window.addEventListener('resize', function () {
    myChart.resize();
  });
};
// 模拟飞行路线模块地图模块
const chinaMap = (echarts) => {
  const myChart = echarts.init(document.querySelector('.map .chart'));
  const geoCoordMap = {
    上海: [ 121.4648, 31.2891 ],
    东莞: [ 113.8953, 22.901 ],
    东营: [ 118.7073, 37.5513 ],
    中山: [ 113.4229, 22.478 ],
    临汾: [ 111.4783, 36.1615 ],
    临沂: [ 118.3118, 35.2936 ],
    丹东: [ 124.541, 40.4242 ],
    丽水: [ 119.5642, 28.1854 ],
    乌鲁木齐: [ 87.9236, 43.5883 ],
    佛山: [ 112.8955, 23.1097 ],
    保定: [ 115.0488, 39.0948 ],
    兰州: [ 103.5901, 36.3043 ],
    包头: [ 110.3467, 41.4899 ],
    北京: [ 116.4551, 40.2539 ],
    北海: [ 109.314, 21.6211 ],
    南京: [ 118.8062, 31.9208 ],
    南宁: [ 108.479, 23.1152 ],
    南昌: [ 116.0046, 28.6633 ],
    南通: [ 121.1023, 32.1625 ],
    厦门: [ 118.1689, 24.6478 ],
    台州: [ 121.1353, 28.6688 ],
    合肥: [ 117.29, 32.0581 ],
    呼和浩特: [ 111.4124, 40.4901 ],
    咸阳: [ 108.4131, 34.8706 ],
    哈尔滨: [ 127.9688, 45.368 ],
    唐山: [ 118.4766, 39.6826 ],
    嘉兴: [ 120.9155, 30.6354 ],
    大同: [ 113.7854, 39.8035 ],
    大连: [ 122.2229, 39.4409 ],
    天津: [ 117.4219, 39.4189 ],
    太原: [ 112.3352, 37.9413 ],
    威海: [ 121.9482, 37.1393 ],
    宁波: [ 121.5967, 29.6466 ],
    宝鸡: [ 107.1826, 34.3433 ],
    宿迁: [ 118.5535, 33.7775 ],
    常州: [ 119.4543, 31.5582 ],
    广州: [ 113.5107, 23.2196 ],
    廊坊: [ 116.521, 39.0509 ],
    延安: [ 109.1052, 36.4252 ],
    张家口: [ 115.1477, 40.8527 ],
    徐州: [ 117.5208, 34.3268 ],
    德州: [ 116.6858, 37.2107 ],
    惠州: [ 114.6204, 23.1647 ],
    成都: [ 103.9526, 30.7617 ],
    扬州: [ 119.4653, 32.8162 ],
    承德: [ 117.5757, 41.4075 ],
    拉萨: [ 91.1865, 30.1465 ],
    无锡: [ 120.3442, 31.5527 ],
    日照: [ 119.2786, 35.5023 ],
    昆明: [ 102.9199, 25.4663 ],
    杭州: [ 119.5313, 29.8773 ],
    枣庄: [ 117.323, 34.8926 ],
    柳州: [ 109.3799, 24.9774 ],
    株洲: [ 113.5327, 27.0319 ],
    武汉: [ 114.3896, 30.6628 ],
    汕头: [ 117.1692, 23.3405 ],
    江门: [ 112.6318, 22.1484 ],
    沈阳: [ 123.1238, 42.1216 ],
    沧州: [ 116.8286, 38.2104 ],
    河源: [ 114.917, 23.9722 ],
    泉州: [ 118.3228, 25.1147 ],
    泰安: [ 117.0264, 36.0516 ],
    泰州: [ 120.0586, 32.5525 ],
    济南: [ 117.1582, 36.8701 ],
    济宁: [ 116.8286, 35.3375 ],
    海口: [ 110.3893, 19.8516 ],
    淄博: [ 118.0371, 36.6064 ],
    淮安: [ 118.927, 33.4039 ],
    深圳: [ 114.5435, 22.5439 ],
    清远: [ 112.9175, 24.3292 ],
    温州: [ 120.498, 27.8119 ],
    渭南: [ 109.7864, 35.0299 ],
    湖州: [ 119.8608, 30.7782 ],
    湘潭: [ 112.5439, 27.7075 ],
    滨州: [ 117.8174, 37.4963 ],
    潍坊: [ 119.0918, 36.524 ],
    烟台: [ 120.7397, 37.5128 ],
    玉溪: [ 101.9312, 23.8898 ],
    珠海: [ 113.7305, 22.1155 ],
    盐城: [ 120.2234, 33.5577 ],
    盘锦: [ 121.9482, 41.0449 ],
    石家庄: [ 114.4995, 38.1006 ],
    福州: [ 119.4543, 25.9222 ],
    秦皇岛: [ 119.2126, 40.0232 ],
    绍兴: [ 120.564, 29.7565 ],
    聊城: [ 115.9167, 36.4032 ],
    肇庆: [ 112.1265, 23.5822 ],
    舟山: [ 122.2559, 30.2234 ],
    苏州: [ 120.6519, 31.3989 ],
    莱芜: [ 117.6526, 36.2714 ],
    菏泽: [ 115.6201, 35.2057 ],
    营口: [ 122.4316, 40.4297 ],
    葫芦岛: [ 120.1575, 40.578 ],
    衡水: [ 115.8838, 37.7161 ],
    衢州: [ 118.6853, 28.8666 ],
    西宁: [ 101.4038, 36.8207 ],
    西安: [ 109.1162, 34.2004 ],
    贵阳: [ 106.6992, 26.7682 ],
    连云港: [ 119.1248, 34.552 ],
    邢台: [ 114.8071, 37.2821 ],
    邯郸: [ 114.4775, 36.535 ],
    郑州: [ 113.4668, 34.6234 ],
    鄂尔多斯: [ 108.9734, 39.2487 ],
    重庆: [ 107.7539, 30.1904 ],
    金华: [ 120.0037, 29.1028 ],
    铜川: [ 109.0393, 35.1947 ],
    银川: [ 106.3586, 38.1775 ],
    镇江: [ 119.4763, 31.9702 ],
    长春: [ 125.8154, 44.2584 ],
    长沙: [ 113.0823, 28.2568 ],
    长治: [ 112.8625, 36.4746 ],
    阳泉: [ 113.4778, 38.0951 ],
    青岛: [ 120.4651, 36.3373 ],
    韶关: [ 113.7964, 24.7028 ]
  };

  const XAData = [];

  const XNData = [
    [ { name: '佛山' }, { name: '佛山', value: 200, tag: 4083 } ]
  ];

  const YCData = [];

  const planePath = 'path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z';

  const color = [ '#a6c84c', '#ffa022', '#46bee9' ]; // 航线的颜色
  const series = [];
  const areaArr = [ [ '西安', XAData ], [ '西宁', XNData ], [ '银川', YCData ] ];
  areaArr.forEach((item, i) => {
    series.push(
      {
        name: item[0] + ' Top3',
        type: 'lines',
        zlevel: 1,
        effect: {
          show: true,
          period: 6,
          trailLength: 0.7,
          color: 'red', // arrow箭头的颜色
          symbolSize: 3
        },
        lineStyle: {
          normal: {
            color: color[i],
            width: 0,
            curveness: 0.2
          }
        },
        data: convertData(item[1], geoCoordMap)
      },
      {
        name: item[0] + ' Top3',
        type: 'lines',
        zlevel: 2,
        symbol: [ 'none' ],
        symbolSize: 10,
        effect: {
          show: false,
          period: 6,
          trailLength: 0,
          symbol: planePath,
          symbolSize: 15
        },
        lineStyle: {
          normal: {
            color: color[i],
            width: 1,
            opacity: 0.6,
            curveness: 0.2
          }
        },
        data: convertData(item[1], geoCoordMap)
      },
      {
        name: item[0] + ' Top3',
        type: 'scatter',
        coordinateSystem: 'geo',
        zlevel: 2,
        rippleEffect: {
          brushType: 'stroke'
        },
        label: {
          normal: {
            show: true,
            position: 'right',
            formatter: '{b}'
          }
        },
        symbolSize: function (val) {
          return val[2] / 8;
        },
        itemStyle: {
          normal: {
            color: color[i]
          },
          emphasis: {
            areaColor: '#2B91B7'
          }
        },
        data: item[1].map(function (dataItem) {
          return {
            name: dataItem[1].name,
            value: geoCoordMap[dataItem[1].name]
              .concat([ dataItem[1].value ])
              .concat([ dataItem[1].tag ])
          };
        })
      }
    );
  });
  const option = {
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        if (params.seriesType === 'effectScatter') {
          return '线路：' + params.data.name + ' + params.data.value[2]';
        } else if (params.seriesType === 'lines') {
          return (
            params.data.fromName +
            '>' +
            params.data.toName +
            '<br />' +
            params.data.value
          );
        } else {
          if (params.data.value[3]) {
            return params.name + '：' + params.data.value[3];
          }
        }
      },
      textStyle: {
        color: 'white'
      },
      backgroundColor: 'rgba(9,9,9,0.7)',
      borderColor: 'rgba(9,9,9,0.7)'
    },
    // legend: {
    //   orient: 'vertical',
    //   top: 'bottom',
    //   left: 'right',
    //   data: ['西安 Top3', '西宁 Top3', '银川 Top3'],
    //   textStyle: {
    //     color: '#fff',
    //   },
    //   selectedMode: 'multiple',
    // },
    geo: {
      map: 'china',
      label: {
        emphasis: {
          show: false,
          color: '#fff'
        }
      },
      // 把中国地图放大了1.2倍
      zoom: 1.2,
      roam: false, // 是否可以缩放
      itemStyle: {
        normal: {
          // 地图省份的背景颜色
          borderColor: 'rgba(20, 41, 87,0.6)',
          areaColor: '#195BB9',
          borderWidth: 1
        },
        emphasis: {
          areaColor: '#2B91B7'
        }
      }
    },
    series: series
  };
  myChart.setOption(option);
  // 监听浏览器缩放，图表对象调用缩放resize函数
  window.addEventListener('resize', function () {
    myChart.resize();
  });
};
function convertData (data, geoCoordMap) {
  const res = [];
  for (let i = 0; i < data.length; i++) {
    const dataItem = data[i];
    const fromCoord = geoCoordMap[dataItem[0].name];
    const toCoord = geoCoordMap[dataItem[1].name];
    if (fromCoord && toCoord) {
      res.push({
        fromName: dataItem[0].name,
        toName: dataItem[1].name,
        coords: [ fromCoord, toCoord ],
        value: dataItem[1].value
      });
    }
  }
  return res;
}
export {
  policyDocument,
  numOfPolicy,
  supportingPro,
  numOfProject,
  projectQuota,
  policyLevel,
  numOfUsers,
  chinaMap
};
